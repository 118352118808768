<template>
    <div class="styled-input" :class="`${error ? 'error-present': '' } ${hasEditPermission ? '': 'disabled'}`">
      <label :class="{light_label}" :for="inputId">{{ label }}</label>
      <input v-if="type !== 'options'"
        :type="type"
        :class="`${theme} ${light_label ? 'light_label' : ''}`"
        :id="inputId"
        :disabled="!hasEditPermission"
        v-model="inputValue"
        :placeholder="placeholder"
        @input="emitInputEvent"
      />
      <select v-else
        class="select-elem"
        :class="theme"
        :id="inputId"
        :disabled="!hasEditPermission"
        v-model="inputValue"
        @change="emitInputEvent">
        <option v-for="option in options" :value="option.value">{{ option.text }}</option>
      </select>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
  </template>
  
  <script>
  import store from '@/store';
  import { mapActions } from 'vuex';
  import { mapState } from 'vuex';
  import Utils from '@/utils';
  export default {
    
    data() {
      return {
        inputValue: '',
      }
    },
    name: 'Input',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true,
      },
      light_label: {
        type: Boolean,
      },  
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      theme: {
        type: String,
        default: 'dark',
      },
      key_ref: {
        type: String,
        default: '',
      },
      max: {
        type: Number,
        default : null,
      },
      min: {
        type: String,
        default : null,
      },
      max_length: {
        type: Number,
        default : null,
      },
      min_length: {
        type: Number,
        default : null,
      },
      options: {
        type: Array,
        default: () => [],
      },
      error: {
        type: String,
        default : null,
      },
      kyc_validate: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      if (this.type == 'date' && this.value) {
        this.inputValue = this.value.split('T')[0];
      } else {
        this.inputValue = this.value;
      }
    },
    methods: {
      ...mapActions(['addNotification']),
    },
    computed: {
      ...mapState(['validationOn', 'user']),
      inputId() {
        // Generate a unique ID for the input
        return 'input-' + Math.random().toString(36).substr(2, 10);
      },
      hasEditPermission() {
        console.log('this.user', this.user)
        console.log('this.disabled', this.disabled)
        console.log(`!["EXTERNAL_AUDITOR", 'INTERNAL_AUDITOR'].includes(this.user.type)`, !["EXTERNAL_AUDITOR", 'INTERNAL_AUDITOR'].includes(this.user.type))
        return this.user && (!["EXTERNAL_AUDITOR", 'INTERNAL_AUDITOR'].includes(this.user.type) || !this.disabled);
      },
      emitInputEvent() {
        // if (this.validationOn) {
        //   Utils.validate
        // }
        let calculated_value = this.inputValue;
        if (this.type == 'number' && this.inputValue) {
          if (this.max && this.inputValue > this.max) {
            calculated_value = +this.max;
            this.inputValue = calculated_value;
          }
          if (this.min && this.inputValue < this.min) {
            calculated_value = +this.min;
            this.inputValue = calculated_value;
          }
        }
        if (this.max_length && this.inputValue.toString().length > this.max_length) {
          this.addNotification({text: 'Input too long', type: 'error'})
        }
        this.$emit('inputChange', [this.key_ref, calculated_value]);
      }
    },
    watch: {
      value(newValue, oldValue) {
        this.inputValue = newValue;
      },
    },
  };
  </script>
  
  <style scoped lang='scss'>
  .styled-input {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  .disabled {
    opacity: 0.7;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #3D5873;
    text-align: left;
  }
  
  input, select {
    background: #38314e;
    border: 1px solid #2b263a;
    outline: none;
    caret-color: #2b263a;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 16px;
    height: 22px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    transition: padding 0.3s;
    &.select-elem {
      width: 100%;
      height: 30px;
    }
    &:focus {
      padding-bottom: 10px;
    }
    padding-left: 0;
  }
  .light {
    background: unset;
    color: black;
    caret-color: black;
  }
  
  .dark {
    background: #38314e;
    color: white;
    caret-color: #2b263a;
  }
  .error-present {
    input, select {
      border-color: red;
      border-width: 1.5px;
    }
  }
  .light_label {
    font-size: 13px;
    margin-bottom: 0;

  }
  .error {
    color: red;
    text-align: left;
    padding-left: 14px;
  }
  input[type="checkbox"] {
    margin-right: auto;
  }
  </style>